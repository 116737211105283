<template>
  <div class="header">
    <img :src="tips_img" alt="">
    <div class="tips_title">{{ iMessage }}</div>
    <el-button type="warning" @click="evCloseBtn">继续填写</el-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 用户 id
      iThirdId: localStorage.getItem('thirdId'),
      // 内容
      iMessage: '',
      // 三方认证 code
      iCode: null,
      // 认证图片
      tips_img: null,
      // 认证成功
      tips_img_suc: require('@/assets/tips/rzcg-png.png'),
      // 认证失败
      tips_img_err: require('@/assets/tips/rzsb-png.png')
    }
  },

  methods: {
    // 控制返回页面展示内容
    fnShowMessage: function () {
      // const oData = this.getQueryString('data')
      // if (oData) {
      //   const { code } = JSON.parse(oData)
      //   Object.assign(this, {
      //     'tips_img': code === '0' ? this.tips_img_suc : this.tips_img_err,
      //     'iMessage': code === '0' ? '认证成功' : '认证失败',
      //     'iCode': code
      //   })
      //   return
      // }
      const { status } = this.$route.query
      if (status) {
        console.log(status)
        Object.assign(this, {
          'tips_img': status === '1' ? this.tips_img_suc : this.tips_img_err,
          'iMessage': status === '1' ? '签署成功' : '签署失败'
        })
        return
      }
      Object.assign(this, {
        'tips_img': this.tips_img_suc,
        'iMessage': '授权成功'
      })
    },
    // 获取 url 参数值
    getQueryString: function (name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      const r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return decodeURIComponent(r[2])
      }
      return null
    },

    // 关闭认证按钮
    evCloseBtn: function () {
      const { iThirdId, iCode } = this
      this.$router.replace({
        'name': 'MainPage',
        'query': { 'third_id': iThirdId, code: iCode }
      })
    }
  },

  mounted () {
    this.fnShowMessage()
  }
}
</script>

<style scoped lang="scss">
.header{
  text-align: center;
  padding-top: 300px;

  .tips_title {
    font-size: 28px;
    line-height: 68px;
    color: #479FF2;

    &.err {
      color: #BDBDBD;
    }
  }

  .el-button{
    margin-top: 240px;
    width: 614px;
    height: 97px;
    font-size: 32px;
    color: #fff;
    border: 0;
    border-radius: 49px;
    background: linear-gradient(90deg, #F2970C, #FEC56E);
  }
}
</style>
